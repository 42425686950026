import React from 'react';
import { Link } from 'gatsby';
import Layout from '../templates/layout';
import MetaTags from '../components/MetaTags';

const TITLE =
  'Refer a VP/Dir Engineering or startup CTO who wants to better understand why and how to build with serverless';

const Page = ({ path }) => (
  <Layout>
    <MetaTags
      title="Referrals"
      path={path}
      description="Refer small teams building on AWS in need of an expert serverless consultant"
    />
    <article className="max-w-5xl mx-auto px-4 md:px-8">
      <section className="max-w-3xl mx-auto">
        <h1 className="page-heading font-extrabold my-8 text-4xl">{TITLE}</h1>
        <p>Hi! Paul Swail here.</p>
        <p>
          You’re reading this page because you’re a trusted friend, client, or
          colleague and you’re curious how to refer someone to me.
        </p>
        <p>
          If you have a colleague, friend, family member, or anyone else who is
          in need of serverless, cloud or DevOps expertise that I can provide, I
          invite you to connect them with me.
        </p>
        <p>
          My solemn promise to you is that I will provide your referral with
          whatever help I can to within the best of my abilities — and if I
          can’t help, I will see to it that they’re connected with someone who
          can help them.
        </p>
        <p>
          And{' '}
          <strong>
            if you’re someone who was referred to me who landed on this page,
            I’m delighted to connect with you
          </strong>
          . Read through this page for a sense of who I can best help, the
          problems I can best help with, and how I might be able to help you.
          Then, <Link to="/contact/">get in touch with me</Link> and tell me
          more about your company, the problem you’re facing, and how I can
          help.
        </p>
        <h2 className="text-center">
          “What type of clients do you work best with?”
        </h2>
        <p>
          My ideal clients are small teams who are building a product on AWS (be
          they in a startup or a small team within a larger company) and have
          specifically chosen to use a serverless tech stack. While they have
          their own developers, they typically have limited (or no) in-house
          experience with delivering a serverless application in AWS right
          through to production and beyond.
        </p>
        <h2 className="text-center">“What problems can you help with?”</h2>
        <p>Here’s a selection of the problems I can help:</p>
        <ul>
          <li>
            Defining an architecture and delivery plan for a new serverless
            application (See my service:{' '}
            <a href="/services/roadmap/">Serverless Application Roadmap</a>)
          </li>
          <li>
            Identifying issues within an existing AWS serverless application,
            recommending how to address them and helping you during
            implementation (See my service:{' '}
            <a href="/services/review/">
              Serverless Architecture &amp; Process Review
            </a>
            )
          </li>
          <li>
            Getting answers to any serverless questions you have, at the
            strategic or technical level (See my service:{' '}
            <a href="/services/call/">Serverless Clarity Call</a>)
          </li>
          <li>
            Building out a secure multi-account AWS environment, DevOps delivery
            pipelines and scaffolded codebase for new greenfield serverless
            applications (See my service:{' '}
            <a href="/services/launchpad/">Serverless Launchpad</a>)
          </li>
        </ul>
        <h2 className="text-center">“How should I refer people to you?”</h2>
        <p>
          The best way to refer someone to me is to send them a link to my
          contact page. That is the best way for us to start the conversation,
          so I can best understand how I can help them and their team.
        </p>
        <p>
          Send them a link to this page:{' '}
          <a href="https://serverlessfirst.com/contact/">
            https://serverlessfirst.com/contact/
          </a>
          . Please let them know that when they fill out the form, they should
          put down your name as the person who referred them to me.
        </p>
        <p>
          Further on down this page, I’ve included an email that you can copy
          and paste and send to anyone you’d like to refer to me. The email
          introduces me, explains what I can help with, and outlines my service
          offerings. (Feel free to make any changes you’d like before you send
          it.)
        </p>
        <h2 id="email-template" className="text-center">
          “Do you have an introduction email I can use?”
        </h2>
        <p>
          Sure do! Just copy and paste this, replacing their name and the
          relevant bits (marked “TK-” so you can easily find them) and send the
          email off to someone that you’ve identified (CC or BCC’ing me at{' '}
          <span className="font-bold">paul@serverlessfirst.com</span>,
          preferably, so I know that you’ve referred them to me and can prepare
          for them to reach out to me).
        </p>
        <blockquote className="border-primary-400 border-l-8 pl-8 py-4 mb-8 font-sans">
          <p>Hi [TK - NAME],</p>
          <p>
            You mentioned that you were looking for help with [TK – Problem or
            issue facing their team OR an outcome they’re looking for, ideally
            related to designing or building a serverless application].
          </p>
          <p>
            I’d like to introduce you to Paul Swail, Independent Cloud Architect
            at ServerlessFirst.com, who specializes in helping software
            development teams deliver production-ready serverless apps on AWS.
            You can view all his service offerings here:{' '}
            <a href="https://serverlessfirst.com/services/">
              https://serverlessfirst.com/services/
            </a>
            .
          </p>
          <p>
            <strong>
              I’m familiar with Paul’s work and highly recommend him as someone
              that you speak with.
            </strong>{' '}
            I’ve CC’d Paul on this email. He’ll be replying in a day or two to
            learn more and set up an initial call with you.
          </p>
          <p>Looking forward to hearing how the conversation goes,</p>
          <p>— [TK Your name and signature here]</p>
        </blockquote>
        <h2 className="text-center">Thank you!</h2>
        <p>
          I deeply appreciate you taking the time to read this page and consider
          referring someone to me.
        </p>
        <p>
          And if there is anyone I can refer to you, just email me and let me
          know and I’ll see who in my network is a match for your services.
        </p>
        <p>Thanks,</p>
        <p>
          — <strong>Paul Swail</strong>, Chief Cloud Architect &amp; Founder,
          ServerlessFirst.com
        </p>
      </section>
    </article>
  </Layout>
);

export default Page;
